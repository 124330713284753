import React from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';

import { xBalance } from '../X';

import diamondIcon from '../assets/diamond500.png';

//props.actionHandler()
//onClick={}

const Pay = (props) => {

  const rc = (p) =>      {      return (parseInt(p) / 10**props.priceDecimals).toPrecision(3);    }

  const eth = (p) =>      {      return Math.round(p * 10**(0-props.priceDecimals),3);    }
  const mikroEth = (p) => {      return p * 10**(6-props.priceDecimals);    }
  const milliEth = (p) => {      return p * 10**(3-props.priceDecimals);    }

/*
  <button
    className={classes.checkButton}
    disabled={ !props.balances || !props.friendExists}
    onClick={() => {
      props.actionHandler2();
    }}
    >
    <p>{ props.friendExists ?  "Check" : "Oops" }</p>
  </button>

  <p> <b>{props.balances ? mikroEth(props.balances[3]).toLocaleString('en-US') : "n/a" }</b> µ{props.priceSymbol}</p>
  <p> <b>{props.balances ? milliEth(props.balances[3]).toLocaleString('en-US') : "n/a" }</b> m{props.priceSymbol}</p>


  <p> <b>{props.balances ? parseInt(props.balances[3]).toLocaleString('en-US') : "n/a" }</b> {props.priceSymbol}</p>
  <p> <b>{props.priceDecimals}</b></p>

*/
  return (
    <div className={classes.Info}>

    { !props.info.isProtected ?

      <div className={classes.boxHigh}>
        <p> Your Earnings:</p>
        <p> <b>{props.balances ? rc(props.balances[3]).toLocaleString('en-US') : "n/a" }</b> {props.priceSymbol}</p>
      </div>

      :
      <div className={classes.box}>
        <p> You are in protected mode (hidden balances). <br/>So you cant see here, whats your earnings, but feel free to pay out.</p>
        <p> Of course, you can switch back to unprotected mode and back as often you want. </p>
      </div>

    }

      <button
        className={classes.digButton}
        disabled={ !props.balances || (props.balances[3] < 1 && !props.info.isProtected) || !props.friendExists}
        onClick={() => {
          props.actionHandler();
        }}
        >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ props.friendExists ?  "Cash Out" : "You need an invitation!" }</p>
      </button>


      <p><br/></p>
      <img alt="Diamond" src={diamondIcon} width="50px"/>

      <div className={classes.box}>
      <p>Cash your earnings out.</p>
      <div><p>
        You decide when it is payday. Cash out in Ethereum direct to your Wallet.
        <br/>
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};

export default Pay;
