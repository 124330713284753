
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

import { sha256 } from 'js-sha256';

// abis now
import ERC20 from '../src/abis/ERC20.json';
//import LEO from '../src/abis/LEO.json';

import SHIELD from '../src/abis/ROCKSHIELD.json' ;
import LIBRY from '../src/abis/ROCK2.json' ;

// styles
import classes from './App.module.css';


// components
import Navigation from './components/Navigation';

import Balance from './components/Balance';
import Block  from './components/Block';
import UnBlock from './components/UnBlock';
import Dig  from './components/Dig';
import Pay  from './components/Pay';
import Info from './components/Info';
import Tx from './components/Tx';

import Origin from './components/Origin';

import { xNullAddress, xOpCode } from './X';

//import AdminTesting from './components/AdminTesting';

import icon from './assets/icon.png';
import icon2 from './assets/icon2.png';


//const ROCK = LIBRY;




const App = ( props ) => {

//  console.log("app props",props);

  const chainId = 4;

  const [account, setAccount] = useState('Connecting to Metamask..');
  const [network, setNetwork] = useState({ id: '0', name: 'none' });
  const [gasPrice, setGasPrice] = useState( 40 * 10**(18-9));

  const [appStatus, setAppStatus] = useState(true);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const [rckTokenContract, setrckTokenContract] = useState('');
//  const [rckStatus, setrckStatus] = useState({ flags: [], dates: []});
//  const [rckFees, setrckFees] = useState({  denominator: '0',  royalties: [{ account: '0x0', value: '0'}],  market: { account: '0x0', value: '0' }  });

  const [rckAccess, setrckAccess] = useState('');

  const [rckPrice, setrckPrice] = useState('');
  const [rckPriceDecimals, setrckPriceDecimals] = useState('');
  const [rckPriceCurrency, setrckPriceCurrency] = useState(xNullAddress);
  const [rckPriceSymbol, setrckPriceSymbol] = useState('');


  const [rckDigCnt, setrckDigCnt] = useState(0);
  const [rckRockFee, setrckRockFee] = useState(0);
  const [rckRockFeeUnit, setrckRockFeeUnit] = useState(0);

  const [rckFriendExists, setrckFriendExists] = useState(false);
  const [rckFriendReal, setrckFriendReal] = useState(false);
  const [rckFriendAddress, setrckFriendAddress] = useState('0x0');

  const [rckContractInfo, setrckContractInfo] = useState({ totals: [0,0,0,0] });
  const [rckContractBalances, setrckContractBalances] = useState([]);

  const [inputBlock, setInputBlock] = useState('');
  const [inputUnBlock, setInputUnBlock] = useState('');
  const [inputDig, setInputDig] = useState('');

  const [inputTxBlock, setInputTxBlock] = useState('');
  const [inputTxAddress, setInputTxAddress] = useState('');
  const [inputTxCurrency, setInputTxCurrency] = useState('');
  const [inputTxCurrencySymbol, setInputTxCurrencySymbol] = useState('');
  const [inputTxCurrencyBalance, setInputTxCurrencyBalance] = useState(0);
  const [inputTxCurrencyDecimals, setInputTxCurrencyDecimals] = useState(18);
  const [inputTxOrder, setInputTxOrder] = useState('');
  const [inputTxCode, setInputTxCode] = useState('');


  const [uriInvite, setUriInvite] = useState('');

  const [tickerEnabled, setTicketEnabled] = useState(false);

  const [rckSubs, setrckSubs] = useState({ childs: [], balances: [] });

  const [chainChangeCounter, setChainChangeCounter] = useState(0);

  const [userBalance, setUserBalance] = useState('0');
  const [stakingBalance, setStakingBalance] = useState('0');




//  const fee = ( 0.001 * 10**18).toString();



  useEffect(() => {
    //connecting to ethereum blockchain
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };

    ethEnabled();
  }, []);


/*
window.ethereum.on('accountsChanged', function (accounts) {
  // Time to reload your interface with accounts[0]!
  console.log(accounts);

  fetchDataFromBlockchain();
});

window.ethereum.on('networkChanged', function (networkId) {
  // Time to reload your interface with the new networkId
  console.log(networkId);

  fetchDataFromBlockchain();
});

*/

  const setLivePebbles = ( f ) => {
    window.livePebbles = f;
  }


//  const getRandomStr = () => Math.random().toString(36).slice(2);


  const crpt = (sash, d) => {

      var m = [ [3,30], [5,9], [11,20] , [8,12]  ];
      var hash = sash.split('');

      if (hash[1]==='x') {
        console.log("##c#");
        for (var i=0; i<m.length; i++) {
          let t = "";
          if (d) {
            t = hash[m[i][0]];
            hash[m[i][0]] = hash[m[i][1]];
            hash[m[i][1]] = t;
          }
          else
          {
            t = hash[m[i][1]];
            hash[m[i][1]] = hash[m[i][0]];
            hash[m[i][0]] = t;
          }
        }
      }
      hash[1]="x";
      return hash.join('');
  }



  const uriParts = window.location.href.split("/");
  let friendAddress = crpt( uriParts.pop() ,false);

//uriParts[2] + (uriParts[-1]==='?' ? "" : '?')


/*
  const validAddress = (ethereumAddress) => {
      let regex = /^0x[0-9a-fA-F]{40}$/;
      if ( ethereumAddress.match(regex)) {
          console.log(ethereumAddress,"ok");

          return true;
      }
      console.log(ethereumAddress,"failed");
      return false;
  }
*/

const fetchSubsFromBlockchain = async ( props ) => {

  if (!appStatus) {
  } else {

    console.log("tree");

    setLoader(true);

    if ( window.ethereum ) {


      await window.ethereum.request({ method: 'eth_requestAccounts' });

      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();


      //await MyToken.at(impl_proxy.address)

      const rckTokenData = SHIELD.networks[networkId];
      if (rckTokenData) {

        const rckToken = new web3.eth.Contract(
          LIBRY.abi,
          rckTokenData.address
        );

        let e = await rckToken.methods
            .memberExists(accounts[0])
            .call();

        if (!e) return;


        let ch = await rckToken.methods
            .getMemberChilds(accounts[0])
            .call();

        let b = [];

        ch.forEach( async (a) => {

          let mb = await rckToken.methods
              .balancesOf(a)
              .call();

          b.push(mb);
        });

        console.log({  childs: ch, balances: b});
        setrckSubs({ childs: ch, balances: b});
      }
    }
    setLoader(false);
  }
}



  const fetchTickerSimulate = async ( props ) => {


    if (!window.livePebbles) {
      console.log("ticker pebbles stopped.");
      return;
    }

    if (props.tick / props.frame < 1){
      console.log("ticker pebbles stopped, no progress ",props);
      return;
    }

    console.log("ticker pebble", props);

    let p = {
      balances: [],
      tick: props.tick,
      counter: props.counter - 1,
      last: props.last,
      value: parseInt(props.value) + props.tick,
      frame: props.frame
    }

    let i = 0;
    while (i < props.balances.length) {
      p.balances[i] = props.balances[i];
      i++;
    }
    p.balances[2] = parseInt(p.value);

    setrckContractBalances(p.balances);

    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
    await waitFor(props.frame * 1000 );

    if (p.counter > 1)
      fetchTickerSimulate( p );
  }


  const getGasPrice = async ( props ) => {

    window.web3 = new Web3(window.ethereum);

    const overhead = "2";
/*
    if (props.overhead ) {
      overhead = props.overhead;
    }
    */
    const gasPriceOverhead = window.web3.utils.toWei(overhead, 'gwei');
    const gasPrice_ = await window.web3.eth.getGasPrice();
    const networkId = await window.web3.eth.net.getId();

    if (networkId == 1) {
      setGasPrice(gasPrice_);
      return gasPrice_ + gasPriceOverhead;
    }

    return window.web3.utils.toWei('40', 'gwei');
 }

  const fetchTickerFromBlockchain = async ( props ) => {

    if (!appStatus) {
    } else {

      let b=[];

      console.log("ticker pebbles fetch #### ",props);


      setTicketEnabled(true);

      if ( window.ethereum ) {


        await window.ethereum.request({ method: 'eth_requestAccounts' });

        //connecting to metamask
        window.web3 = new Web3(window.ethereum);
        let web3 = window.web3;

        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const gasPrice_ = await web3.eth.getGasPrice();

        const rckTokenData = SHIELD.networks[networkId];
        if (rckTokenData) {

          const rckToken = new web3.eth.Contract(
            LIBRY.abi,
            rckTokenData.address
          );

          b = await rckToken.methods
              .balancesRockOf(accounts[0])
              .call();

          if (b) {
            let balance=[];
            let i=0;
            for (i = 0; i<6; i++) {
              balance[i] = b[i];
            }
            for (i = 7; i<9; i++) {
              balance[i] = rckContractBalances[i];
            }
            setrckContractBalances(balance);
            console.log("rockBalances",balance);
          } else {
            console.log("rockBalances failed");
          }

        // LIVEFIX
        if (networkId == 1 ) {
          setGasPrice(gasPrice_);
        }



        }

        let t = props.first ? 15 : 60;


        if (props.last.length > 2) {

           let tick = (b[2] - props.last[2]) / props.lastTick ;

           if (!window.livePebbles) {
             setLivePebbles(true);
             console.log("ticket pebbles started.");

             fetchTickerSimulate( { counter: t*4, balances: b, tick:  tick/4, value: b[2], last: props.last, frame: 1/4 } );
           }
        }



        const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
        await waitFor(t * 1000);

        fetchTickerFromBlockchain( { last: b, first: false , lastTick: t} );
      }
   }

  }


  const changeNetwork = async ( props ) => {

   console.log("change of network requested cnt:",chainChangeCounter);

   setChainChangeCounter( chainChangeCounter + 1 );

   if (window.ethereum) {

     window.web3 = new Web3(window.ethereum);

     try {
       await window.ethereum.request({
       method: 'wallet_switchEthereumChain',
         params: [{ chainId: window.web3.utils.toHex(props.chainId) }],
       });

       //window.location.reload();


       fetchDataFromBlockchain();
/*
       if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))
        fetchDataFromBlockchain();
       else
        window.location.reload();
*/
     } catch (error) {
       console.error(error);
     }
   }
 }

  const fetchDataFromBlockchain = async () => {


    if ( window.ethereum ) {

      console.log("chain",window.ethereum.networkVersion, chainId);
      if (window.ethereum.networkVersion != chainId) {
        changeNetwork({ chainId: chainId });

        return;
      }


      console.log("eth checked");

      await window.ethereum.request({ method: 'eth_requestAccounts' });


      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      console.log(web3);


      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);


      //loading users network ID and name
      const networkId = await web3.eth.net.getId();
      const networkType = await web3.eth.net.getNetworkType();
      setNetwork({ ...network, id: networkId, name: networkType });

      console.log("x2");

//      const balanceEthereum = await web3.eth.getBalance(accounts[0]);



      /*
      , function(err, result) {
        if (err) {
          console.log(err)
        } else {
          console.log(web3.utils.fromWei(result, "ether") + " ETH");
          setUserBalance(web3.utils.fromWei(result, "ether"));
        }
      });
      */

      const rckTokenData = SHIELD.networks[networkId];
      if (rckTokenData) {
        let web3 = window.web3;
        const rckToken = new web3.eth.Contract(
          LIBRY.abi,
          rckTokenData.address
        );


        setrckTokenContract(rckToken);

        window.abi = {
          SHIELD: SHIELD.abi,
          rock: LIBRY.abi
        }
        window.token = rckToken;
        window.account = accounts[0];
        window.sha256 = sha256;


        // TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON



/*
        console.log("contractInfo");

        let __totals= await rckToken.methods.totals().call();
        console.log("x");
        let __decimals= await rckToken.methods.decimals().call();
        console.log("xx");
        let __feeDenominator= 1000; //await rckToken.methods.feeDenominator().call(),
        console.log("x");
        let __symbol= await rckToken.methods.symbol().call();
        console.log("xxx");
        let __name= await rckToken.methods.name().call();
        console.log("x");
        let __totalFlow=    await rckToken.methods.totalFlow(xNullAddress).call();
        console.log("xxxxx");
        let __blockTime=    await rckToken.methods.getTimeStamp().call();
        console.log("x");


        let _lastBlocking= await rckToken.methods.lastBlockingOf(accounts[0]).call();
        console.log("xxxxxx");
       let _isProtected= await rckToken.methods.isProtected(accounts[0]).call();
        console.log("x");

        let _apy= await rckToken.methods.getAPY( Date.now() ).call();
        console.log("x");
        */


          /*       ###############  PRICE FIRST - UI RELATED !!! ########## */

          //  fetching balance of Testtoken and storing in state
          let price = await rckToken.methods
            .getPrice()
            .call();

          setrckPrice(price.price);
          setrckPriceCurrency(price.currency);
          setrckPriceDecimals(price.decimal);
          //          setrckPrice(price.forSale);
          console.log(price);


          if (price.currency == xNullAddress) {
            const result = await web3.eth.getBalance(accounts[0]);

            setrckPriceSymbol( "ETH" );

            console.log("balance:",web3.utils.fromWei(result, "ether") + " ETH");
            setUserBalance(web3.utils.fromWei(result,"ether"));
          } else {

            let erc = await erc20Helper(price.currency, accounts[0]);

            setrckPriceSymbol( erc.s);

            console.log("balance:",  erc.b/ 10**price.decimal,  erc.s);
            setUserBalance( erc.b / 10**price.decimal );
          }


          //  fetching balance of Testtoken and storing in state
          let contractInfo = {
              totals: await rckToken.methods.totals().call(),
              decimals: await rckToken.methods.decimals().call(),
              feeDenominator: 1000, //await rckToken.methods.feeDenominator().call(),
              symbol: await rckToken.methods.symbol().call(),
              name: await rckToken.methods.name().call(),
              totalFlowEther:    await rckToken.methods.totalFlow(xNullAddress).call(),
//              totalFlowUSDT:    await rckToken.methods.totalFlow(USDT.address).call(),
              blockTime:    await rckToken.methods.getTimeStamp().call(),

              lastBlocking: await rckToken.methods.lastBlockingOf(accounts[0]).call(),
              isProtected: await rckToken.methods.isProtected(accounts[0]).call(),

              apy: await rckToken.methods.getAPY( Date.now() ).call()
          }


          setrckContractInfo(contractInfo);
          console.log(contractInfo);


          console.log("contractBalances");
          let contractBalances = await rckToken.methods.balancesOf(accounts[0]).call();
          setrckContractBalances(contractBalances);
          console.log(contractBalances);



/*
          //  fetching balance of Testtoken and storing in state
          let access = await rckToken.methods
            .getAccess(accounts[0])
            .call();

          setrckAccess(access);
          console.log(access);
*/



          let digCnt = await rckToken.methods
            .cntDig(accounts[0])
            .call();
          setrckDigCnt(digCnt);


          let rockFee = await rckToken.methods
            .getFee()
            .call();
          setrckRockFee(rockFee.fee);
          setrckRockFeeUnit(rockFee.unit);

/*
          //  fetching balance of Testtoken and storing in state
          let aw = await rckToken.methods
            .getAccessWallets()
            .call();

          console.log(aw);
*/

          // TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF

          let friendExists = false;
          let friendReal = false;



          let promAddress = await rckToken.methods
            .getPromotedBy(accounts[0])
            .call();
          console.log("got promAddress",promAddress,"account",accounts[0]);
          console.log("friend address", friendAddress);

          if (promAddress != xNullAddress) {
            friendAddress = promAddress;

            friendReal = true;
            friendExists = true;
          } else {
            if ( web3.utils.isAddress(friendAddress)) {
              friendReal = true;
              friendExists = await rckToken.methods
                .memberExists(friendAddress)
                .call();
            } else {
              console.log("friendAddress not valid");
            }
          }


          setrckFriendReal(friendReal);
          setrckFriendExists(friendExists);
          setrckFriendAddress(friendAddress);

          console.log("check",friendExists, friendReal);

          setUriInvite( [ uriParts[0] ,  uriParts[1] ,  uriParts[2] + (uriParts[2][-1]==='?' ? "" : '?') , crpt(accounts[0],true) ].join("/") );


          setLivePebbles(false);

          if (!tickerEnabled)
            fetchTickerFromBlockchain({ last: [], first: true });

          fetchSubsFromBlockchain({});

      }

      //removing loader
      setLoader(false);
    } else if (!window.web3) {

      console.log("no ethereum available");
      setAppStatus(false);

      setAccount('Metamask is not detected');
      setLoader(false);
    } else {

      setAccount('Miau');
      setLoader(false);
    }
  };



  const inputBlockHandler = (received) => {
    setInputBlock(received);
  };

  const inputUnBlockHandler = (received) => {
    setInputUnBlock(received);
  };

  const inputTxBlockHandler = (received) => {
    setInputTxBlock(received);
  };

  const inputTxAddressHandler = (received) => {
    setInputTxAddress(received);

    setInputTxCode(0x00);
    setInputTxOrder('');
  };

  const inputTxCurrencyHandler = (received) => {
    setInputTxCurrency(received);

    setInputTxCode(0x00);
    setInputTxOrder('');
  };

  const inputTxOrderHandler = (code, target, currency, amount, order ) => {

//    const rckDo = async ( op_, v_, t_) => {
    console.log("got order",code,target,currency,amount,order);

    setInputTxCode(code);
    setInputTxAddress(target);
    setInputTxCurrency(currency);
    setInputTxBlock(amount);
    setInputTxOrder(order);

    // ethereum

    rckCurrencyInfo( currency, amount ) ;

    // only for non-rock and non-eth
    // if (received != txCurrency && received != rckTokenContract.address) {
    //  rckApproval( received );
    // }
  };


  const erc20Helper = async ( currency, account ) => {

        let web3 = window.web3;

        const coinToken = new web3.eth.Contract(
          ERC20.abi,
          currency
        );

        let s = await coinToken.methods
          .symbol()
          .call();

        let b = await coinToken.methods
          .balanceOf(account)
          .call();

        let d = await coinToken.methods
          .decimals()
          .call();

        return {
          s: s,
          b: b,
          d: d,
          t: coinToken
        };
  }


  const erc20AA = async ( currency, account, operator, amount ) => {

        let web3 = window.web3;

        const coinToken = new web3.eth.Contract(
          ERC20.abi,
          currency
        );


        console.log("check currency allowance for ", account,"/",operator );
        let a = await coinToken.methods
          .allowance(account,operator)
          .call();

        console.log("currency allowance for ", account,"/",operator, " is ", a, "should ",amount);

        if (a < amount) {
          console.log("allowance too low, approve for more...");
           await rckApproval( currency, amount );
        } else {
          console.log("allowance enough, do not approve for more...");
        }
  }



  const rckCurrencyInfo = async ( txCurrency, txAmount ) => {

//    setLoader(true);

    let web3 = window.web3;

    if (txCurrency == '0x0') {
      txCurrency=xNullAddress;
    }

    // Ethereum fallback
    if (txCurrency == xNullAddress) {
      setInputTxCurrencySymbol( "ETH" );
      setInputTxCurrencyDecimals( 18  );
      setInputTxCurrencyBalance( await web3.eth.getBalance(account));
      return;
    }


    let erc = await erc20Helper(txCurrency, account);

    console.log("currency symbol:", erc.s);
    setInputTxCurrencySymbol( erc.s );
    console.log("currency balance:", erc.b);
    setInputTxCurrencyBalance( erc.b );
    console.log("currency decimals:", erc.d);
    setInputTxCurrencyDecimals( erc.d );

    //let ad = window.token._address; //rckTokenData.address;

    await erc20AA( txCurrency, account, rckTokenContract.options.address, txAmount );

 }


  const rckApproval = async ( txCurrency, txAmount ) => {

//    setLoader(true);

    let web3 = window.web3;
    let ad = window.token._address; //rckTokenData.address;

    if (ad == txCurrency) {
        console.log("no need to approve for this contract.");
        return;
    }

    const coinToken = new web3.eth.Contract(
      ERC20.abi,
      txCurrency
    );

    txAmount =  web3.utils.toWei("1000000");

   await coinToken.methods
    .approve( ad, txAmount  )
    .send({
      from: account,
      gasLimit: web3.utils.toHex(220000),
      gasPrice: web3.utils.toHex(await getGasPrice())
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log("approval confirmed");

    })
    .on('transactionHash', (hash) => {
      console.log("approval tx");
    })
    .on('receipt', (receipt) => {
      console.log("approval receipt");
    })
    .on('error', function(error) {
      console.log('Error Code:', error.code);
      console.log(error.code);
  //    setLoader(false);
    });

  }








  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  const rckUnBlock = async ( ) => {
      await rckDo( xOpCode.unBlock , inputUnBlock, xNullAddress, null ,null);
  }
  const rckBlock = async () => {
    await rckDo( xOpCode.block , inputBlock, xNullAddress, null ,null);
  }

  const rckTxBlock = async () => {
    if ( inputTxOrder==="" ) {
      await rckDo( xOpCode.txBlock , inputTxBlock, inputTxAddress, null ,null);
    } else {
      await rckDo( xOpCode.txPay , inputTxBlock, inputTxAddress, inputTxCurrency ,inputTxOrder);
    }
  }

  const rckProtect = async () => {
    await rckDo( xOpCode.protect , getRandomInt(932103213) , xNullAddress, null ,null);
  }

  const rckCheck = async () => {
    await rckDo( xOpCode.check , getRandomInt(932551113) , xNullAddress, null ,null);
  }


  const rckDo = async ( op_, v_, t_, c_, o_) => {
    if (!appStatus) {
    } else {

      if (!v_ || parseInt(v_) < 1) { return; }


      if ( window.web3.utils.isAddress(t_)) {
        let exists = await rckTokenContract.methods
          .memberExists(t_)
          .call();
          if (!exists) {
            console.log("address not exists");
            return;
          }
      } else {
        console.log("address not valid");
      }

      let rFee = rckRockFee;
      if (op_ == xOpCode.block || op_ == xOpCode.unBlock || op_ == xOpCode.txBlock ) {
        rFee = (rckRockFee * v_ / rckRockFeeUnit);
        v_ = v_ * 10**rckContractInfo.decimals;
      }

      if (op_ == xOpCode.txPay ) {
        op_ = 0x123;
      }

      rFee = (rFee).toString();

/*
      let op_ = 0x111;
      let v_  = 1000;
      let t_  = 0;

      let rckTokenContract = window.token;
      let account = window.account;
*/
      console.log("xc:got  args", op_, t_, v_, c_, o_);





//--------------------------------------

/*

  op_ = 0x300 | 0x11;  // base | mode
  t_  = "0xA0a9Cb43FCe79d33e7F3B2D4840963EC7893d40a"; //  0x54A3615Fac9AC5b03e28E9375Fe0079fbF4232C7";
  c_  = "0x5F3151Dd5a90bf91AfF676f91cc2ADb6E0dB4258"; // USDT
  v_  = 100310000; //100.31 * 10**10;
  o_  = "AJ112ZZ_e3e213__";


  xNullAddress =  '0x0000000000000000000000000000000000000000';

  const rckTokenContract = new web3.eth.Contract(
    window.leoabi,
    "0x74503e1dD5E3668ccc9C0f2EAd0E71Fc6A7d1098"
    //"0x5808653469Eedc5c0F2e5e6C096bD0438FEe8372"
    //"0xf15038535943f3c04D54b4bBc446f1F64A635233"
    //"0xab37da98d99D378F0bF0484eE421790C3B7618aB"
    //"0x40F5fd3Fcb17Aa0Fdf253042B713b5e022B2E1A9"
    //"0x2C489D4d9BD9C7dE1A57A0535410C2B76c27FB5c"
    //"0x10Ce64E688b658c660bF71043609154474eAFb06"
    //"0x134998a22B4F50A08C8f0a61f38Be7AED6D80570"
    //"0x1Ec16D928F145666601D2adA465Cae9722812371"
    //"0xB62Cb6623a8b80cDDEc61D27b638F2caaEdcd2cE"
  );

  */

      let u = window.web3.utils;

      function rm0x(r) {
        return r.substring(2);
      }



      const b = new Uint32Array(8);
      crypto.getRandomValues(b);
      let k = window.web3.utils.toHex(btoa(b[0]*b[1]*b[2]*b[3]*b[4]*b[5]*b[6]*b[7]))

      let rn_ = await rckTokenContract.methods
        .getPre(window.account)
        .call();



        if (rn_ == '0') {
          let result = await rckTokenContract.methods
            .randomize()
            .send({
              from: window.account,
              gasLimit: window.web3.utils.toHex(400000),
              gasPrice: window.web3.utils.toHex(await getGasPrice())
            })
            rn_ = await rckTokenContract.methods
              .getPre(window.account)
              .call();
        }

      //let rn = "0x" + rn.toString(16);
      let rn = u.padLeft(u.toHex(rn_),64);
      console.log(rn);




      const a = new Uint32Array(12);  // FFFFFFFF   bytes8 !
      crypto.getRandomValues(a);


      if (t_ == xNullAddress || t_ == null) {
          t_  = "0x54A3615Fac9AC5b03e28E9375Fe0079fbF4232C7"; // Master of Rockiverse
      }
      if (c_ == xNullAddress || c_ == null ) {
          c_  = "0x5093098409328409328409328409832408324032"; //USDT
      }
      if (o_ == null) {
          o_ = [ a[1].toString(16) , a[2].toString(16), a[1].toString(16) , a[2].toString(16) ].join();
      }
      let maxv_ = parseInt("0x" + "FFFFF" + "FFFFF" + "FFFFF" + "FFFFF" + "FF");
      if ( v_ > maxv_ ) {
        console.log("max value capacity ",maxv_," (",v_,")reached, abort!");
//        return;
      }


      // build param one
      let fill_ = [ a[0].toString(16) , a[1].toString(16) , a[2].toString(16)  , a[4].toString(16) ];
      let fill20  = fill_.join("").substring(0,20);

      let one_ = [ u.padLeft( op_.toString(16), 4) , fill20, rm0x(t_) ];
      let one  = "0x" + one_.join("");

      console.log("xc: one: ",one_,one,one.length,   one_[2].length);


      // build param one
      let two_ = [ u.padLeft( v_.toString(16), 24)  ,  rm0x(c_) ];
      let two  = "0x" + two_.join("");

      console.log("xc: two: ",two_,two,two.length,   two_[1].length);


      // build param three
      let o__ =  sha256( o_ );

      let three_ = [  o__ ];
      let three  = "0x" + three_.join("");

      console.log("xc: three: ",three_,three,three.length,   three_[0].length, o__.length);



      console.log("xc: encode",[rn,one,two,three]);

      let w = await rckTokenContract.methods.cx( k , rn ).call();
      let x = await rckTokenContract.methods.cx( one, k ).call();
      let y = await rckTokenContract.methods.cx( two,  k ).call();
      let z = await rckTokenContract.methods.cx( three , k ).call();

/*
      console.log("encoded", [w,x,y,z]);

      let _k = await rckTokenContract.methods.cx( w,  rn ).call();
      let _op = await rckTokenContract.methods.cx( x, _k ).call();
      let _t = await rckTokenContract.methods.cx( y,  _k ).call();
      let _v = await rckTokenContract.methods.cx( z , _k ).call();

      console.log("decoded",_k,[_op,_t,_v]);
*/

/*
        let miau = await rckTokenContract.methods
          .decode2( w,x,y,z, window.account )
          .call();
        console.log(miau);


    //    sha = sha256( encodePacked (target, currency, amount, order ));

        let miau2 = await rckTokenContract.methods
          .encodePacked( t_, c_, v_, "0x"+o__ )
          .call();
        console.log(miau2);

        let miau3 = await rckTokenContract.methods
          .encodePacked(
          miau.target,
          miau.currency,
          miau.amount,
          miau.order
         )
          .call();
        console.log(miau3);


        let miau4 = await rckTokenContract.methods
          .getSha256(
          miau3
        )
          .call();
        console.log(miau4);

return;
*/

      setLoader(true);
      let result = await rckTokenContract.methods
        .rock( w,x,y,z)
        .send({
          from: account,
          value: window.web3.utils.toHex(rFee),
          gasLimit: window.web3.utils.toHex(500000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
        })
        .on('receipt', (receipt) => {
          setLoader(false);
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });

    //  console.log("rck: res:",result);
//    */
    }

  };




  const inputDigHandler = (received) => {
    setInputDig(received);
  };

  const rckDig = async ( ) => {

    if (!appStatus) { return; }

//    console.log(inputDig, friendAddress);
    console.log({
      "inputdig": inputDig,
      "price": rckPrice,
      "dec": rckPriceDecimals
    });

    if (!inputDig || parseInt(inputDig) < 1) { return; }

    // rckPrice is in Ethereum!
    let e = (0).toString();
    if (rckPriceCurrency == xNullAddress) {
       e = ( inputDig *  rckPrice / (10**rckPriceDecimals)).toString();
    } else {
//      let ad = window.token._address; //rckTokenData.address;
      console.log(rckTokenContract);
      await erc20AA( rckPriceCurrency, account, rckTokenContract.options.address, inputDig * rckPrice );
    }



    let r = (inputDig * 10**rckContractInfo.decimals ).toString();

    console.log("price",{
      "price": rckPrice,
      "price/10**18": rckPrice / (10**rckPriceDecimals),
      "e": e,
      "amount": inputDig,
      "r": r
    });

    let gasLimit = 1100000;
    if (rckDigCnt > 0) {
      gasLimit = 600000;
    }

    setLoader(true);
    rckTokenContract.methods
      .dig( r , rckFriendAddress )
      .send({
        from: account,
        value: window.web3.utils.toHex(window.web3.utils.toWei( e, 'ether')),
        gasLimit: window.web3.utils.toHex(gasLimit),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);

        fetchDataFromBlockchain();

        fetchSubsFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };

/*
  const rckCheck = async ( ) => {

    if (!appStatus) { return; }

    let rFee = (rckRockFee / 10**18).toString();

    setLoader(true);
    rckTokenContract.methods
      .recalc()
      .send({
        from: account,
//        value: window.web3.utils.toHex(window.web3.utils.toWei( rFee, 'ether')),
        gasLimit: window.web3.utils.toHex(50000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };
*/


  const rckPayOut = async ( ) => {

    if (!appStatus) { return; }

    setLoader(true);
    rckTokenContract.methods
      .payOut()
      .send({
        from: account,
//        value: window.web3.utils.toHex(window.web3.utils.toWei( fee, 'ether')),
        gasLimit: window.web3.utils.toHex(300000),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };





  let metamaskURI = "https://metamask.io/download/";
  let braveURI = "https://brave.com/";
  let metamaskDeepURI = "https://metamask.app.link/dapp/" + window.location.href;



  if ( !appStatus ) {

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))

      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon} alt="logo" className={classes.icon} />
            <h1> please use mobile Metamask compatible Wallet</h1>
            <p>{account}</p>
            <p>Visit <a href={metamaskURI} target="_blank" rel="noopener noreferrer">Metamask</a> first</p>
            <p></p>
            <h1> open this Link in the Metamask's own Browser</h1>
            <p></p>
            <p>Visit <a href={metamaskDeepURI} rel="noopener noreferrer">DAPP in Metamask</a> then</p>
            <p></p>
            <p>Android Users should enable
            Apps*Standard&nbsp;Apps*Links*MetaMask*Addresses*metamask.app.link before.
            </p>
          </div>
        </div>
      )
   else
      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon2} alt="logo" className={classes.icon} />
            <h1> please install Brave-Browser on Desktop or any Metamask compatible Wallet-Plugin </h1>
            <p>{account}</p>
            <p>Visit <a href={braveURI}>Brave</a> first</p>
          </div>
        </div>
      )
  }



  if ( network.id != chainId && chainChangeCounter > 10)

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <img src={icon} alt="logo" className={classes.icon} />
          <h1> wrong Network </h1>
          <p>{network.id}</p>
          <p></p>
          <p>Switch Network to <b>Rinkeby</b> (on Metamask Mobile via Button "...")</p>
        </div>
      </div>
    )


  const changePage = () => {
    if (page === 1) {
      setPage(2);
    } else if (page === 2) {
      setPage(1);
    }
  };

  if ( /origin/i.test(  window.location.href  ))
    return (
      <Origin/>
    );

  return (
    <div className={classes.Grid}>
      {loader ? <div className={classes.curtain}></div> : null}
      <div className={classes.loader}></div>
      <div className={classes.Child}>
        <Navigation changePage={changePage} account={account}   access={rckAccess} symbol={rckPriceSymbol}>
        <div>
          <Balance
            account={account}
            network={network}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            Dig={rckDig}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            uriParts={uriParts}
            actionHandler={rckBlock}
            protectHandler={rckProtect}
          />
        </div>
        <div className={classes.Dig}>
          <Dig
            page={page}
            Dig={rckDig}
            amount={inputDig}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            price={rckPrice}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            actionHandler={rckDig}
            inputHandler={inputDigHandler}
            userBalance={userBalance}
            gasPrice={gasPrice}
          />
        </div>
        <div className={classes.inStore}>
          <Block
            page={page}
            actionHandlerBlock={rckBlock}
            inputHandlerBlock={inputBlockHandler}
            actionHandlerUnBlock={rckUnBlock}
            inputHandlerUnBlock={inputUnBlockHandler}
            protectHandler={rckProtect}
            checkHandler={rckCheck}
            info={rckContractInfo}
            balances={rckContractBalances}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.inStore}>
          <Tx
            page={page}
            account={account}
            actionHandler={rckTxBlock}
            checkHandler={rckCheck}
            inputHandler={inputTxBlockHandler}
            inputAddressHandler={inputTxAddressHandler}
            inputCurrencyHandler={inputTxCurrencyHandler}
            inputOrderHandler={inputTxOrderHandler}
            currencySymbol={inputTxCurrencySymbol}
            currencyBalance={inputTxCurrencyBalance}
            currencyDecimals={inputTxCurrencyDecimals}
            currencyValue={inputTxBlock}
            contract={rckTokenContract}
            info={rckContractInfo}
            balances={rckContractBalances}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.outStore}>
          <UnBlock
            page={page}
            actionHandler={rckUnBlock}
            inputHandler={inputUnBlockHandler}
            info={rckContractInfo}
            balances={rckContractBalances}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.pay}>
          <Pay
            page={page}
            info={rckContractInfo}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            balances={rckContractBalances}
            contract={rckTokenContract}
            actionHandler={rckPayOut}
            actionHandler2={rckCheck}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
          />
        </div>
        <div className={classes.info}>
          <Info
            account={account}
            network={network}
            gasPrice={gasPrice}
            info={rckContractInfo}
            balances={rckContractBalances}
            page={page}
            Dig={rckDig}
            priceSymbol={rckPriceSymbol}
            priceDecimals={rckPriceDecimals}
            contract={rckTokenContract}
            friendAddress={rckFriendAddress}
            friendExists={rckFriendExists}
            friendReal={rckFriendReal}
            uriInvite={uriInvite}
            subs={rckSubs}
          />
        </div>
         </Navigation>
      </div>
    </div>
     );

}

export default App;
