import React from 'react';
import classes from './Profile.module.css';

import etherIcon from '../assets/ether.png';
import rockIcon from '../assets/rockIcon.png';
import dappIcon from '../assets/dapp.png';

import metaMaskIcon from '../assets/icon.png';

import { xNullAddress, xTotal, xBalance } from '../X';



const Profile = (props) => {

  const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

  const nanoEth = (p) =>  {      return Math.round(p * 10**(9-props.priceDecimals));    }
  const mikroEth = (p) => {      return Math.round(p * 10**(6-props.priceDecimals));    }
  const milliEth = (p) => {      return Math.round(p * 10**(3-props.priceDecimals));    }
  const eth = (p) =>      {      return Math.round(p * 10**(0-props.priceDecimals),3);    }


    const etherScan = ( networkId ) => {
      if (networkId === 4) {
        return "https://rinkeby.etherscan.io/address/";
      }
      if (networkId === 1) {
        return "https://etherscan.io/address/";
      }
    }


    let d = (new Date(props.info.blockTime * 1000)).toLocaleString();
    let dl = (new Date(props.info.lastBlocking * 1000)).toLocaleString();

    const datum = (s) => {
           let dt = new Date(s * 1000);
           return (
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
      }

//    let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);


    let web3Dotted = "" + window.location.href.substring(0,22) + "...";
//    let uriInviteDotted = "" + props.uriInvite.substring(0,20) + "...";


  return (
    <div className={classes.profile}>
      <div className={classes.info} width="200">
        <img alt="Ethereum" src={etherIcon} width="50px"/>
        <p>Ethereum Network</p>
        <ul>
        <li> network: <b>{props.network.name}</b> (id: <b>{props.network.id}</b>)</li>
        <li> web3: <b>{web3Dotted}</b></li>
        <li> gasprice: <b>{nano(props.gasPrice)}nETH or GWEI</b></li>
        <li> blockTime: <b>{props.info.blockTime}</b></li>
        <li> blockDate: <b>{  d }</b></li>
        </ul>
        <p><br/></p>
        {
          window.ethereum.isMetaMask ?
            <img alt="Metamask" src={metaMaskIcon} width="80px"/>
            :
            ""
        }
        <p>Wallet</p>
        <ul>
        <li> address: <b><a href={etherScan(props.network.id) + props.account} target="scan"  rel="noopener noreferrer" >{props.account}</a></b> ({"<-Click"})</li>
        <li> name: <b>
          { window.ethereum.isMetaMask ? "Metamask" : (
             window.ethereum.isCoinbaseWallet ? "Coinbase Wallet" : (
               window.ethereum.isTrustWallet ? "Trust Wallet" : (
                 "Unknown Wallet"
               )
            )
          )
          }
        </b></li>
        </ul>
        <p><br/></p>
        <img alt="Rock" src={rockIcon} width="80px"/>
        <p>Contract</p>
        <ul>
        <li> name:     <b>{props.info.name}</b></li>
        <li> symbol:     <b>{props.info.symbol}</b></li>
        <li> address: <b><a href={etherScan(props.network.id) + props.contract._address} target="scan"  rel="noopener noreferrer" >{props.contract._address}</a></b> ({"<-Click"})</li>
        <li> decimals:  <b>{props.info.decimals}</b></li>
        <li> totals: <b>{(parseInt(props.info.totals[0])  / 10**props.info.decimals).toLocaleString('en-US')}</b> ROCKs</li>
        <li> totalsRocked: <b>{(parseInt(props.info.totals[1])  / 10**props.info.decimals).toLocaleString('en-US')}</b> ROCKs</li>
        <li> totalEther: <b>{ (props.info.totalFlowEther / 10**(18-3)).toLocaleString('en-US') }</b> mETH</li>
        <li> current APY: <b>{props.info.apy ? props.info.apy.rate / 10 : "n/a"}</b>% (valid till { props.info.apy ?  datum(props.info.apy.till): "n/a" })</li>
        </ul>
        { props.friendReal ? (
          <div>
            <p>Brotherhood</p>
            <ul>
            <li> brother: <b>{props.friendAddress === '' ? "is missing" : props.friendAddress }</b>{ props.friendExists ? "" : "(has not mined ROCKs yet)"}</li>
            <li> invitation: <b>{props.uriInvite.substring(0,30) + "..." + props.uriInvite.substr(-4) }</b> </li>
            <li> invited by you: <ul>
            { props.subs.childs.length > 0  && props.subs.balances.length > 0 ?
                props.subs.childs.map((address,idx) => (
                  <li>{  address.substring(0,6) + "..." + address.substr(-4) }:
                   <b>{props.subs.balances[idx][xBalance.erc20] / 10**props.info.decimals}</b> rocks,
                   <b>{props.subs.balances[idx][xBalance.blocked] / 10**props.info.decimals}</b> pebbles,
                   <b>{props.subs.balances[idx][xBalance.digSum] / 10**props.info.decimals}</b> rocks digged,
                   <b style={{color: "white"}}>{milliEth(props.subs.balances[idx][xBalance.summarizedAPYTotal])}</b> m{props.priceSymbol} earned,
                   <b style={{color: "white"}}>{milliEth(props.subs.balances[idx][xBalance.provision])}</b> m{props.priceSymbol} prov
                  </li>
                ))
                :
                "nobody"
            }
            </ul>
            </li>
            </ul>
          </div>
         ) : (
           <div>
            <p>YOU NEED AN INVITATION</p>
            <ul>
            <li> ask your rocking Rock Brothers around the world to be invited. Join and Earn Ethereum!
                  </li>
            </ul>
            <p><br/></p>
          </div>
        )}
        <p>Balances</p>
        <ul>
        <li> available: <b>{props.balances ? parseInt(props.balances[xBalance.erc20] / 10**props.info.decimals).toLocaleString('en-US') : "n/a"}</b> ROCKs</li>
        <li> rocked: <b>{
           props.info.isProtected ?
              "unknown"
              :
              (
                props.balances ? parseInt(props.balances[xBalance.blocked] / 10**props.info.decimals).toLocaleString('en-US') : "n/a"
              )
          }</b> ROCks</li>
        <li> (digged: <b>{props.balances ? parseInt(props.balances[6] / 10**props.info.decimals).toLocaleString('en-US') : "n/a" }</b> ROCKs)</li>
        <li> protected: <b>{props.info.isProtected ? "yes, hidden" : "no" }</b></li>
        </ul>
        <p>Rewards</p>
        <ul>
        <li> currently: <b>{props.balances ? (parseInt(props.balances[xBalance.summarized])/ 10**props.info.decimals).toLocaleString('en-US') : "n/a" }</b> Pebbles</li>
        <li> resolved: <b>{props.balances ? (parseInt(props.balances[xBalance.summarizedTotal])/ 10**props.info.decimals).toLocaleString('en-US') : "n/a" }</b> Pebbles</li>
        <li> earned: <b>{
          props.info.isProtected ?
             "unknown"
             :
             (
              props.balances ? mikroEth(props.balances[xBalance.summarizedAPY]).toLocaleString('en-US') : "n/a"
             )
          }</b> µETH</li>
        <li> total: <b style={{color: "white"}}>{props.balances ? milliEth(props.balances[xBalance.summarizedAPYTotal]).toLocaleString('en-US') : "n/a" }</b> m{props.priceSymbol}</li>
        <li> provisioned: <b style={{color: "white"}}>{props.balances ? milliEth(props.balances[xBalance.provision]).toLocaleString('en-US'): "n/a" }</b> m{props.priceSymbol}</li>

        <li> current APY: <b>{props.info.apy ? props.info.apy.rate / 10 : "n/a"}</b>% (valid till { props.info.apy ?  datum(props.info.apy.till): "n/a" })</li>

        <li> last time rocked: <b>{props.info.lastBlocking === 0 ? "not rocked yet" : dl }</b></li>


        </ul>
        <p><br/></p>
        <img alt="Dapp" src={dappIcon} width="100px"/>
        <p>Principle of Decentralized App</p>
        <div><p>
          This decentralized Application (<b>DApp</b>) is provided <u>free of charge</u> by the <b><a style={{color: "white"}} href="javascript:window.location.href+='&origin'">Interactive Software Development LLC</a></b>.
          It does not interact with third services or stores your data.
          <br/>
          <br/>
          This <b>DApp</b> communicates with your <b>Wallet</b> only. Your <b>Wallet</b> communicates with the <b>Ethereum Network</b>.
          You, the user, has full control of any operation of this <b>DApp</b>. You allow read access on your <b>Wallet</b> on start.
          Every transaction that is made by this <b>DApp</b> on your <b>Wallet</b>, needs explicit confirmation by You.
          <br/>
          <br/>
          Time, Place, Fees and Costs of transactions may vary depending on runtime parameters of the <b>Contracts</b>, the <b>Ethereum Network</b>,
           your <b>Wallet</b> and any <b>Ethereum Provider</b> between <b>Wallet</b> and the <b>Ethereum Network</b>.
          <br/>
          </p>
        </div>
        <p>Disclaimer</p>
        <div>
        <p>
          THIS <b>DAPP</b>, CONTENT AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS
          SERVICE PROVIDERS DO NOT WARRANT OR GUARANTEE THE ACCURACY, COMPLETENESS, OR ADEQUACY OF THIS <b>DAPP</b> OR ANY CONTENT OR
          SERVICES AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED IN THE CONTENT OR OTHER DATA, INFORMATION,
           OR USER CONTENT THAT MAY BE PROVIDED THROUGH THIS <b>DAPP</b>. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS
           EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THIS <b>DAPP</b>, CONTENT, SERVICES
           AND OTHER SUBJECT MATTER OF THIS AGREEMENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
            ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION
            OR ADVICE GIVEN BY INTERACTIVE SOFTWARE DEVELOPMENT LLC, ITS EMPLOYEES, OR SERVICE PROVIDERS WILL INCREASE THE SCOPE OF,
             OR CREATE ANY NEW WARRANTIES. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS MAKE NO WARRANTY THAT
              (a) THIS <b>DAPP</b> WILL MEET YOUR REQUIREMENTS,
              (b) THIS <b>DAPP</b> WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
              (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS <b>DAPP</b> WILL BE ACCURATE OR RELIABLE,
              (d) THE QUALITY OF ANY CONTENT OR SERVICES PURCHASED OR OBTAINED BY YOU THROUGH THIS <b>DAPP</b> WILL MEET YOUR EXPECTATIONS, OR
              (e) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED.
            ANY CONTENT OR SERVICES DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS <b>DAPP</b> IS DONE AT YOUR OWN DISCRETION AND RISK
            AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </p>
          <p><br/></p>
          <p><br/></p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
