
export const xAccessCode = {
   oneOf: 0,
   master: 1,
   admin:  2,
   chief:  3
};

export const xNullAddress =  '0x0000000000000000000000000000000000000000';

export const xOpCode = {
   block: 0x111,
   unBlock: 0x222,
   check: 0x333,
   protect: 0x666,
   txBlock: 0x777,
   txPay: 0x123
};


export const xBalance = {
  erc20: 0,
  blocked: 1,
  summarized: 2,
  summarizedAPY: 3,
  summarizedTotal: 4,
  summarizedTotalAPY: 5,
  digSum: 6,
  provision: 7,
  sale: 8,
  digQual: 9,
  digQualChildCount: 10,
  digSumChild: 11,
  lastBlockingTime: 12,
  protected: 13,
  rn: 14,
  isNoticed: 15
};


export const xTotal = {
 supplyERC20: 0,
 supplyBlocked: 1,
 summarized: 2,
 totalSummarizedAPY: 3,
 totalDigged: 4
};
