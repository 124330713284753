import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

import classes from './Profile.module.css';
import classesShake from './Shake.css';

import rockIcon from '../assets/rockIcon.png';
import copyicon from '../assets/copymini.png';

import { xBalance } from '../X';


const Profile = (props) => {

/*
    const etherScan = ( networkId ) => {
      if (networkId == 4) {
        return "https://rinkeby.etherscan.io/address/";
      }
      if (networkId == 1) {
        return "https://etherscan.io/address/";
      }
    }
*/
    const [copiedInfo, setCopiedInfo] = useState(false);
    const [copiedInfo2, setCopiedInfo2] = useState(false);


    let dottedContractAddress = "";
//    let contractAddress = "";

/*
    if (props.contract) {
      dottedContractAddress = "" + props.contract._address.substring(0,6) + "..." + props.contract._address.substr(-4);
//      contractAddress = props.contract._address;
    }

    let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
*/


    let uriInviteDotted = props.uriInvite.substring(0,20) + "...";
    let uriInviteDeepLink = [ "https://metamask.app.link/dapp", props.uriInvite.replace("https://","") ].join("/");


    const copyAddress = (event) => {
       navigator.clipboard.writeText(uriInviteDeepLink);

       setCopiedInfo(true);

       setTimeout(() => {
         setCopiedInfo(false);
      }, 3000);

    };

    const copyAddress2 = (event) => {
       navigator.clipboard.writeText(props.contract._address);

       setCopiedInfo2(true);

       setTimeout(() => {
         setCopiedInfo2(false);
      }, 3000);
    };


/*
    const closeBox = (event) => {
       setCopiedInfo(false);
    };

    <li> total: <b>{props.balances ? parseInt(props.balances[xBalance.erc20]) + parseInt(props.balances[xBalance.blocked]): "n/a"}</b> ROCKs</li>
    <li> rewarded: <b>{props.balances ? props.balances[xBalance.summarized] : "n/a" }</b> Pebbles</li>

*/
//    console.log("dCA",dottedContractAddress,props.contract._address);
    const eth = (p) =>      {      return Math.round(p * 10**(0-props.priceDecimals),3);    }
    const mikroEth = (p) => {      return parseInt(p) * 10**(6-props.priceDecimals);    }
    const milliEth = (p) => {      return parseInt(p) * 10**(3-props.priceDecimals);    }
    const datum = (s) => {
           let dt = new Date(s * 1000);
           return (
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
      }

    const rc = (p) =>      {      return (parseInt(p) / 10**props.priceDecimals).toPrecision(3);    }

//    let dl = (new Date(props.info.lastBlocking * 1000)).toLocaleString();

  return (
    <div className={classes.profile}>
      <div style={{ display: "flex", "padding-left": "10%" }}>
          <button
            className={classes.checkButton}
            onClick={() => {
                props.protectHandler();
            }}
            >
            <p>{ props.info.isProtected ?  "Show" : "Hide" }</p>
          </button>
      </div>
      <div className={classes.info} width="200" >
        <p><br/></p>
        <div style={{ "fontWeight": "800"}}>
        Earn Ethereum by Rocking Rocks
        <br/>
        </div>
        <p><br/></p>
        <div style={{ display: "flex",  "justify-content": "center" }}>
          <div  style={{  position: "relative", top: "2em"}}><b>{props.balances ? ((parseInt(props.balances[xBalance.erc20]) + parseInt(props.balances[xBalance.blocked]))  / 10**props.info.decimals).toLocaleString('en-US') : "n/a"}</b> Rocks</div>
          <div style={{ width: "30%"}}><img className="shake" style={{ position: "relative", margin: "auto"}} alt="Rock" src={rockIcon} width="80px"/></div>
          <div  style={{  position: "relative", top: "2em"}}><b>{props.balances ? parseInt(props.balances[xBalance.summarized] / 10**props.info.decimals).toLocaleString('en-US') : "n/a" }</b> Pebbles</div>
        </div>
        <div>
          <ul className={classes.larger}>
          { props.info.isProtected ?
              <li className={classes.animatestone} >--*-° <b>Some</b> Rocks are rocked °-*--</li>
            :
            ( props.balances  && props.balances[xBalance.blocked] > 0 ?
              <li className={classes.animatestone} >--*-° <b>{parseInt(props.balances[xBalance.blocked] / 10**props.info.decimals).toLocaleString('en-US')}</b> Rocks are rocked °-*--</li>
                :
              <li ><b>no</b> ROCks rocking</li>
            )
          }
          <li>( <b>{props.balances ? parseInt(props.balances[xBalance.erc20] / 10**props.info.decimals).toLocaleString('en-US') : "n/a"}</b> Rocks available )</li>
          </ul>
        </div>
        <p><br/></p>
        <p>Profits</p>
        <ul>
        <li> earned: <b style={{color: "white"}}>{
           props.info.isProtected ?
            "some"
            :
            ( props.balances ? rc(props.balances[xBalance.summarizedAPY]).toLocaleString('en-US') : "n/a" )
        } {props.priceSymbol}</b> (cash out when You like)</li>
        <li> provisioned: <b style={{color: "white"}}>{props.balances ? rc(props.balances[xBalance.provision]).toLocaleString('en-US')  : "n/a" } {props.priceSymbol}</b> (already on Your wallet!)</li>
        <li> community: <b style={{color: "white"}}>{props.balances ? rc(props.balances[xBalance.sale]).toLocaleString('en-US')  : "n/a" } {props.priceSymbol}</b> </li>
        <li> current APY: <b>{props.info.apy ? props.info.apy.rate / 10 : "n/a"}</b>% (valid till { props.info.apy ?  datum(props.info.apy.till): "n/a" })</li>
        </ul>

        <p><br/></p>
        { props.friendExists ? (
          <div>
            <p>Invite your Rock Brothers</p>
            <ul>
                <li> invite uri: <b>{uriInviteDotted}</b>    &nbsp;
                  <img alt="Copy" width="15px" src={copyicon} onClick={copyAddress} />
                  { copiedInfo ?
                   "Copied"
                   : null
                  }
                  </li>
                  <li><br/><QRCode
            	          value={uriInviteDeepLink}
                        eyeRadius={8}
                        qrStyle="dots" ecLevel="H" size="200"
                        fgColor="white" bgColor="rgb(71 36 6)"
                        logoImage={rockIcon} logoWidth="50"
                        removeQrCodeBehindLogo={true}
                        />
                  </li>
                  <li>
                    Invite your friends, allow them to rock ROCKs too. Their mobile should handle this code.
                    For every rock your friend may dig, you'll get some reward in Ethereum.
                  </li>
                  <li>Android Users should enable
                  Apps*Standard&nbsp;Apps*Links*MetaMask*Addresses*metamask.app.link before.
                  </li>
            </ul>
            <p><br/></p>
            </div>
           ) : (
             <div>
              <p>YOU NEED AN INVITATION</p>
              <ul>
              <li> ask your rocking Rock Brothers around the world to be invited. Join and Earn Ethereum!
                    </li>
              </ul>
              <p><br/></p>
            </div>
          )
        }

        <p>Contract</p>
        <ul>
        <li> name:     <b>{props.info.name}</b></li>
        <li> symbol:     <b>{props.info.symbol}</b></li>
        <li>address: <b>{props.contract._address}</b> &nbsp;
          <img alt="Copy" width="15px" src={copyicon} onClick={copyAddress2}/>
          { copiedInfo2 ?
           "Copied"
           : null
          }
          </li>
        <li> totals: <b>{parseInt(props.info.totals.length>1 ? props.info.totals[0]: 0).toLocaleString('en-US')}</b></li>
        <li> totalsRocked: <b>{parseInt(props.info.totals.length>1 ? props.info.totals[1]: 0).toLocaleString('en-US')}</b></li>
        </ul>
      </div>
      <p><br/></p>
    </div>
  );
};


export default Profile;
