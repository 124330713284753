import React, { useState } from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import mineIcon from '../assets/mineOut500.png';


const UnBlock = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {

    event.preventDefault();

    let i = Math.round(event.target.value)
    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(i);
    }

    props.inputHandler(event.target.value);
  };

/*
  const goMax = () => {
    setInputValue(props.userBalance);
    props.inputHandler(props.userBalance);
  };

*/

  return (
    <div className={classes.Info}>

      <p><br/></p>

      <div className={classes.boxHigh} >
        <p> rocking: <b>{props.balances ? ( props.info.isProtected ? "Some" : parseInt(props.balances[1] / 10**props.info.decimals).toLocaleString('en-US')) : "n/a" }</b> ROCks</p>
      </div>

      <div className={classes.inputDiv}>
        <input
          placeholder="Amount"
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.digButton}
        disabled={ !props.balances || ( props.balances[1] < 1 && !props.info.isProtected) || !props.friendExists ||   ( inputValue > props.balances[1]  && !props.info.isProtected) }
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ props.friendExists ? ( inputValue === "" ? "Please enter amount" : ( inputValue > props.balances[1] ? "too much" : "UnRock") ) : "You need an invitation!" }</p>
      </button>

      <div className={classes.boxHigh} >
        <p> unrocked: <b>{props.balances ? parseInt(props.balances[0] / 10**props.info.decimals).toLocaleString('en-US') : "n/a"}</b> ROCKs</p>
      </div>

      <p><br/></p>
      <img alt="Rock" src={mineIcon} width="100px"/>
      <div className={classes.box}>
      <p>Unrocking ends your earning.</p>
      <div><p>
        You are always free to unrock you Rocks. This ends your earning of Ethereum.
        If you do not need all of your Rocks, keep them rocking for You!
        <br/>
        <br/>
        What you've earned is not touched by unrocking. So feel free to unrock and even rock again.
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default UnBlock;
