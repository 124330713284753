import React, { useState } from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import rockIcon from '../assets/rock500.png';



const Dig = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {
    event.preventDefault();

    let i = parseInt(event.target.value);


    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(""+i);
    }
    props.inputHandler(i);

  };

  const goMax = () => {
    const plus = 5; // %
    const gas = 600000; //439182
    const costs = props.gasPrice * gas * ((100 + plus)/100) /  10**(18-9);

    // FIXME: if ethereum, add costs !!
    let i = Math.floor( props.userBalance * 10**props.priceDecimals / props.price ); /// 10**props.priceDecimals) * (10**6 )));

    console.log("gomax",props.gasPrice,gas, plus, costs, props.userBalance,  props.priceDecimals, props.price);

    if (i < 1) {
       i = 0;
       setInputValue("");
     } else{
       setInputValue(""+i);
     }


    props.inputHandler(i);
//    props.inputHandler(props.userBalance);
  };


  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);


  return (
    <div className={classes.Info}>

      <h5 onClick={goMax} className={classes.goMax}>
        Your balance: { Math.floor(props.userBalance * 100)/100 } {props.priceSymbol}
        &nbsp;
        <button
          className={classes.allButton}
          disabled={!props.friendExists}
          onClick={goMax}
        >
          <p>All</p>
        </button>

      </h5>



      <div className={classes.boxHigh}>
        <p><b>{ inputValue < 100 ? "Start with 100 ROCKS if you like, not just" : ( inputValue >= 10000 ? "don't overdo it" : "you can dig now")}</b></p>
      </div>


      <div className={classes.inputDiv}>
        <input
          placeholder="Amount"
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.digButton}
        disabled={!props.friendExists}
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ props.friendExists ? ( inputValue === "" ? "Please enter amount" : "Dig") : "You need an invitation!" }</p>
      </button>


      <div className={classes.box}>
        <p>Digging Fee:  {(props.price / 10**props.priceDecimals) + " " + props.priceSymbol+" per ROCK"}</p>
        <p>Your Rock Brother: <b>{props.friendExists ? dottedFriendAddress: "has not digged ROCKs yet"}</b></p>
      </div>
      <div className={classes.boxHigh}>
        <p><br/> DOING IN CRYPTO IS RISKY. <br/> THERE ARE HIGH CHANCES BUT HIGH RISKS.</p>
      </div>


      <p><br/></p>
      <img alt="Rock" src={rockIcon} width="50px"/>

      <div className={classes.box}>
      <p><b>Digging helps you start Rocking</b><br/>-<br/>A smart consensus mechanism</p>
      <div>
      <p>
        Digging is like mining with a very low carbon footprint. Nothing is premined here. You start digging by your own. Every ROCK you dig, is your's.
        There are only digged Rocks out there! In Terms of Coinomics: 100% Digger!
        <br/>
      </p>
      <p>
        You start with digging some Rocks. Any Rock is ERC20 compatible. Feel free to transfer unrocked Rocks to your friends. But it is also a good idea to
        rock your Rocks here and earn Ethereum.
        <br/>
        <br/>
        { props.friendExists ? "" : "If your Rock Brother - the one who invited you - has not digged Rocks yet, you cannot start. Sharing is caring."}
        <br/>
        </p>
        <p><br/></p>
      </div>
      <div className={classes.box}>
        <p><b>The Digging Paradigm</b><br/>-<br/>Why theres is no DEX Offer to trade ROCK on start?</p>
        <div>
          <p>
            ROCK is digged. Digging is selfmining. Every coin is minted by
          and to the wallet who digges. There is NO ROCK before, no pre-mining,
          no shadow budget and every decision to swap against other tokens is
          made by the user himself.

          <br/>
          <br/>
           So it is very likely that there will be Pairs ROCK/OtherCoin and
          vice-versa on decentral exchanges in the near future. But it is
          impossible that this could exist before a fair start of digging.
           ROCK's tocenomics says: 100% owned by Users.
           <br/>
           <br/>

          Feel free to compare this fairness with premined token projects,
          where the "team" has premined 50% before, where you only buy
          their effortless booked coins. Also compare this with owner defined allocation
          of USDT, that is driven by Tether Limited in Hongkong, who never
          proofed, that they have enough USDT to back at least 10% of the
          volume in US-Dollar.
          <br/>
          <br/>

          So if you think, it is to risky to be one of the first in
          a fair digging project, keep your initial digging as
          low as 100 ROCKS and tell your friends about. Feel free to
          dig more later.
          </p>
          </div>
      </div>
      </div>

    </div>
  );
};

export default Dig;
