import React, { useState } from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import digIcon2 from '../assets/dig2.png';
import mineIcon from '../assets/mine500.png';
//import rightIcon from '../assets/rightIcon.png';


const Block = (props) => {
  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {

    event.preventDefault();

    let i = Math.round(event.target.value)

    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(i);
    }

    props.inputHandlerBlock(event.target.value);
    props.inputHandlerUnBlock(event.target.value);
  };

  const goMax = () => {
    console.log( props.balances[0], 10**props.info.decimals);
    setInputValue( props.balances[0] / 10**props.info.decimals);
    props.inputHandlerBlock(props.balances[0] / 10**props.info.decimals);
    props.inputHandlerUnBlock(props.balances[0] / 10**props.info.decimals);
//    props.inputHandler(props.balances[0] / 10**props.info.decimals);
//    props.inputHandler(props.userBalance);
  };


  return (
    <div className={classes.Info}>


      <h5 className={classes.goMax}>
       unrocked: <b>{props.balances ? parseInt(props.balances[0] / 10**props.info.decimals).toLocaleString('en-US') : "n/a"}</b> ROCKs
        &nbsp;
        <button
          className={classes.allButton}
           onClick={goMax}
        >
          <p>All</p>
        </button>
        </h5>


      <div className={classes.inputDiv}>
        <input
          placeholder="Amount"
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.blockButton}
        disabled={ !props.balances || props.balances[0] < 1 || !props.friendExists  ||   inputValue > props.balances[0] }
        onClick={() => {
          props.actionHandlerBlock();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ inputValue === "" ? "Enter amount" : ( inputValue > parseInt(props.balances[0] / 10**props.info.decimals) ? "too much" : "Rock") }</p>
      </button>

      &nbsp;

      <button
        className={classes.blockButton}
        disabled={ !props.balances || ( props.balances[1] < 1 && !props.info.isProtected) ||   ( inputValue > props.balances[1]  && !props.info.isProtected) }
        onClick={() => {
          props.actionHandlerUnBlock();
          setInputValue('');
        }}
      >
        <img src={digIcon2} alt="dig icon" className={classes.digIcon} />
        <p>{  inputValue === "" ? "Enter amount" : ( inputValue > parseInt(props.balances[1] / 10**props.info.decimals) ? "too much" : "UnRock")  }</p>
      </button>


      <div className={classes.boxHigh} >
        <p> rocking: <b>{props.balances ? ( props.info.isProtected ? "Some" : parseInt(props.balances[1] / 10**props.info.decimals).toLocaleString('en-US')) : "n/a" }</b> ROCks</p>
      </div>


      <p><br/></p>
      <img alt="Rock" src={mineIcon} width="100px"/>
      <div className={classes.box}>
      <p><b>Principle of Rocking</b><br/>-<br/>A new kind of staking</p>
      <div><p>
        You decide to declare some of Your Rocks as "rocked". You will not be able to transfer these rocked Rocks until you unrock them.
        For the period of "rocking the Rocks" you'll get compensated continuously. You're free to rock or unrock whenever you like. And don't forget to pay out your earnings.
        <br/>
        <br/>
        When rocking, all of your Rocks are kept in your Wallet. No Rock is being transfered, swapped, burned or changes the ownership.
        <br/>
        </p>
      </div>
      </div>

    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Block;
